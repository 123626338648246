import classNames from 'classnames';
import { FC, useState } from 'react';
import { useRawMode } from '~/context/RawModeContext';
import { useInstallPrompt } from '~/hooks/useInstallPrompt';
import { isIOS } from '~/utils/helpers';
import { requestNotificationPermission } from '~/utils/notifications';

import Overlay from '../Overlay/Overlay';
import PwaInstructions from '../PwaInstructionsModal/PwaInstructions';
import PwaInstructionsAndroid from '../PwaInstructionsModal/PwaInstructionsAndroid';
import DownloadPwa from './DownloadPwa';
import EnableNotifications from './EnableNotifications';
import Notifications from './Notifications';
import styles from './NotificationsCenter.module.scss';

interface Props {
  isPwa: boolean;
  isSubscribed: boolean;
  onClose: () => void;
}

const NotificationsCenterModal: FC<Props> = ({
  isPwa,
  isSubscribed,
  onClose,
}) => {
  const { isRaw } = useRawMode();

  const [showInstructions, setShowInstructions] = useState(false);
  const { handleInstallClick } = useInstallPrompt(() =>
    setShowInstructions(true),
  );

  const ios = isIOS();

  const showPwaStuff = !isPwa || !isSubscribed;

  async function handleEnableNotifications() {
    const vapidPublicKey =
      'BMgVnXNfwQAoMHYF03csbjiY_hBfd6ivpfx6CzdWvFSFZQLVCGYe1_IxHq7NldpM4mEul5XwHZt-q2VKMYmpM2Q';

    await requestNotificationPermission(vapidPublicKey, isRaw);
  }

  return (
    <>
      <Overlay onClick={onClose} />
      <div className={styles.modal}>
        <div className={styles.headingHolder}>
          <div className={styles.heading}>Notifications</div>
          <div className={styles.badge}>0</div>
        </div>
        <Notifications />
        {showPwaStuff && (
          <>
            <div className={styles.headingHolder}>
              <div className={styles.heading}>Home Screen App</div>
              <div className={classNames(styles.badge, styles.badgeActive)}>
                1
              </div>
            </div>
            {!isPwa && (
              <>
                {showInstructions ? (
                  <>
                    {ios ? (
                      <PwaInstructions
                        onClose={() => setShowInstructions(false)}
                      />
                    ) : (
                      <PwaInstructionsAndroid
                        onClose={() => setShowInstructions(false)}
                      />
                    )}
                  </>
                ) : (
                  <DownloadPwa onInstall={handleInstallClick} />
                )}
              </>
            )}
            {isPwa && !isSubscribed && (
              <EnableNotifications
                handleEnableNotifications={handleEnableNotifications}
              />
            )}
          </>
        )}
      </div>
    </>
  );
};

export default NotificationsCenterModal;
