const MoonIcon = () => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M17.3099 11.6849C16.0706 11.9692 14.7794 11.934 13.5574 11.5826C12.3354 11.2313 11.2227 10.5753 10.3236 9.67625C9.42456 8.77717 8.76859 7.66445 8.41725 6.44246C8.06592 5.22047 8.03074 3.92927 8.31502 2.68996L8.31492 2.68994C7.01017 2.99092 5.80978 3.63567 4.83837 4.55725C3.86696 5.47883 3.15996 6.64364 2.79076 7.93075C2.42156 9.21785 2.40364 10.5803 2.73884 11.8767C3.07404 13.1731 3.75015 14.3561 4.69697 15.3029C5.64379 16.2497 6.8268 16.9258 8.12317 17.261C9.41955 17.5963 10.782 17.5783 12.0691 17.2091C13.3562 16.8399 14.521 16.1329 15.4426 15.1615C16.3642 14.1901 17.009 12.9897 17.3099 11.685L17.3099 11.6849Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default MoonIcon;
