import classNames from 'classnames';
import { motion } from 'framer-motion';
import { FC } from 'react';
import { useRawMode } from '~/context/RawModeContext';
import PwaCenterNotifsIcon from '~/icons/PwaCenterNotifsIcon';
import { checkSubscriptionStatus } from '~/utils/checkSubscribtionStatus';
import { setCookie } from '~/utils/helpers';
import { requestNotificationPermission } from '~/utils/notifications';

import styles from './PwaNotifsModal.module.scss';

interface Props {
  onClose: () => void;
}

const PwaNotifsModal: FC<Props> = ({ onClose }) => {
  const { isRaw } = useRawMode();

  const handleRemindMeLater = () => {
    setCookie('pwa_notifs', 'seen', 7);
    onClose();
  };

  async function handleEnableNotifications() {
    const vapidPublicKey =
      'BMgVnXNfwQAoMHYF03csbjiY_hBfd6ivpfx6CzdWvFSFZQLVCGYe1_IxHq7NldpM4mEul5XwHZt-q2VKMYmpM2Q';

    await requestNotificationPermission(vapidPublicKey, isRaw);
    const status = await checkSubscriptionStatus();
    status && onClose();
  }
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.1 }}
      className={styles.modalOverlay}
    >
      <div className={styles.modal}>
        <div className={styles.topRowNotifs}>
          <div className={styles.iconNotifs}>
            <PwaCenterNotifsIcon />
          </div>
          <div>
            <div className={styles.title}>Enable Notifications</div>
            <div className={styles.description}>
              Get updated on Top News posts twice per day
            </div>
          </div>
        </div>
        <div className={styles.bottomNotifs}>
          <button className={styles.button} onClick={handleEnableNotifications}>
            Enable
          </button>
          <button
            className={classNames(styles.button, styles.buttonSecondary)}
            onClick={handleRemindMeLater}
          >
            Remind me later
          </button>
        </div>
      </div>
    </motion.div>
  );
};

export default PwaNotifsModal;
