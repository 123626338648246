import { captureRemixErrorBoundaryError } from '@sentry/remix';
import layoutSyles from 'app/scss/layout/layout.module.scss';
import { AnimatePresence } from 'framer-motion';
import PullToRefresh from 'pulltorefreshjs';
import { FC, PropsWithChildren, useEffect, useState } from 'react';
import 'reset-css';
import { ChartbeatSectionProvider } from '~/context/ChartbeatSectionContext';
import { UserProvider } from '~/context/UserContext';
import { authTokenCookie } from '~/cookies.server';
import useCookieConsent from '~/hooks/useCookieConsent';
import '~/scss/global.scss';

import { LoaderFunction, json } from '@remix-run/node';
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useLocation,
  useNavigation,
  useRevalidator,
  useRouteError,
} from '@remix-run/react';

import DownloadPwaModal from './components/DownloadPwaModal/DownloadPwaModal';
import HeadLinks from './components/HeadLinks/HeadLinks';
import HeadLinksRaw from './components/HeadLinks/HeadLinksRaw';
import Header from './components/Header/Header';
import Loader from './components/Loader/Loader';
import NewsletterModal from './components/NewsletterModal/NewsletterModal';
import PwaInstructionsModal from './components/PwaInstructionsModal/PwaInstructionsModal';
import PwaNotifsModal from './components/PwaNotifsModal/PwaNotifsModal';
import Sidebar from './components/Sidebar/Sidebar';
import ToastList from './components/Toast/ToastList';
import TopicsModal from './components/TopicsModal/TopicsModal';
import { RawModeProvider } from './context/RawModeContext';
import { ThemeProvider, useTheme } from './context/ThemeContext';
import { ToastProvider } from './context/ToastContext';
import { ViewModeProvider } from './context/ViewModeContext';
import { getUserFromToken } from './services/userService';
import { MinimalTagResource, Tag } from './types';
import { checkSubscriptionStatus } from './utils/checkSubscribtionStatus';
import {
  getCookie,
  handleGAPWAEvent,
  isIOS,
  isMobileDevice,
  isPWAInstalled,
} from './utils/helpers';

type TagsLoaderResponse = {
  data: MinimalTagResource[];
};

type modalTagsResponse = {
  hot_tags: Tag[];
  main_tags: Tag[];
};

export const loader: LoaderFunction = async ({ request }) => {
  const cookieHeader = request.headers.get('Cookie');
  const token = await authTokenCookie.parse(cookieHeader);
  const url = new URL(request.url);

  const isRaw = url.hostname !== process.env.DEFAULT_DOMAIN;

  let user = null;
  if (token) {
    user = await getUserFromToken(token);
  }

  const headers: HeadersInit = {};

  if (token && token.token) {
    headers['Authorization'] = `Bearer ${token.token}`;
  }

  const tagsResponse: Response = await fetch(
    `${process.env.API_URL}/tags/main-categories-excluding-favorites`,
    {
      headers,
    },
  );

  const tags: TagsLoaderResponse = await tagsResponse.json();

  const topTagsResponse: Response = await fetch(
    `${process.env.API_URL}/tags/top-discover-tags`,
    {
      headers,
    },
  );

  const topTags: TagsLoaderResponse = await topTagsResponse.json();

  let modalTags: modalTagsResponse | null = null;

  if (user && !user.stats.topics.follow_count) {
    const modalTagsResponse: Response = await fetch(
      `${process.env.API_URL}/tags/user-categories?filter[only_main]=true`,
      {
        headers,
      },
    );

    modalTags = await modalTagsResponse.json();
  }

  return json({
    modalTags: modalTags,
    topTags: topTags.data,
    tags: tags.data,
    user,
    isRaw,
    googleTagToken: isRaw
      ? process.env.GOOGLE_TAG_TOKEN_RAW
      : process.env.GOOGLE_TAG_TOKEN_DEFAULT ?? '',
  });
};

export default function Root() {
  const { topTags, tags, user, googleTagToken, isRaw, modalTags } =
    useLoaderData<typeof loader>();
  const location = useLocation();

  const [showSubscribeModal, setShowSubscribeModal] = useState(false);
  const [showTopicsModal, setShowTopicsModal] = useState(false);
  const [showPwaModal, setShowPwaModal] = useState(false);
  const [showPwaInstructionsModal, setShowPwaInstructionsModal] =
    useState(false);
  const [showNotifsModal, setShowNotifsModal] = useState(false);
  const [isMenuOpened, setIsMenuOpened] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const { revalidate } = useRevalidator();

  const navigation = useNavigation();

  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;

    if (navigation.state !== 'idle') {
      timer = setTimeout(() => {
        setLoading(true);
      }, 500);
    } else {
      setLoading(false);
      if (timer) {
        clearTimeout(timer);
      }
    }
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [navigation.state]);

  useEffect(() => {
    if ('serviceWorker' in navigator) {
      const swFile = isRaw ? '/worker.js' : '/default.worker.js';

      navigator.serviceWorker
        .register(swFile)
        .then((registration) => {
          if (registration.active) {
            registration.active.postMessage('reset-badge');
          }
        })
        .catch((error) => {
          console.error('❌ Service Worker Registration Failed:', error);
        });
    }
  }, [isRaw]);

  useEffect(() => {
    const isMobile = isMobileDevice();
    const cookie = getCookie('pwa_modal');
    const isPwa = isPWAInstalled();
    if (!isPwa && !cookie && isMobile) {
      setShowPwaModal(true);
      handleGAPWAEvent('promo-seen');
    }
    const notifsCookie = getCookie('pwa_notifs');

    async function fetchSubscriptionStatus() {
      const isSubscribed = await checkSubscriptionStatus();
      if (!isSubscribed) {
        setShowNotifsModal(true);
      }
    }

    if (isPwa && !notifsCookie && isMobile) {
      fetchSubscriptionStatus();
    }
  }, []);

  useEffect(() => {
    const isPwa = isPWAInstalled();

    const handleVisibilityChange = () => {
      if (isPwa) {
        if (document.visibilityState === 'visible') {
          revalidate();
        }
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [revalidate]);

  useEffect(() => {
    if (
      user &&
      !localStorage.getItem('newsletterModalShown') &&
      !user.mailer_lite_groups.length
    ) {
      setShowSubscribeModal(true);
      localStorage.setItem('newsletterModalShown', 'true');
    }
  }, [user]);

  useEffect(() => {
    if (isMenuOpened || showTopicsModal || showSubscribeModal) {
      document.body.style.overflow = 'hidden';
    }

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isMenuOpened, showTopicsModal, showSubscribeModal]);

  useEffect(() => {
    setIsMenuOpened(false);
  }, [location]);

  useEffect(() => {
    const cookie = getCookie('reader_topics_modal');
    if (!cookie && modalTags) {
      setShowTopicsModal(true);
    }
  }, [modalTags]);

  useCookieConsent();

  const checkIfTextPage = () => {
    const pathSegments = location.pathname.split('/').filter(Boolean);
    const slug = pathSegments[0];
    if (slug === 'privacy-policy') return true;
  };

  useEffect(() => {
    const isPwa = isPWAInstalled();
    const ios = isIOS();
    if (!ios && !isPwa) return;

    const ptr = PullToRefresh.init({
      mainElement: 'main',
      onRefresh() {
        window.location.reload();
      },
      instructionsPullToRefresh: '',
      instructionsReleaseToRefresh: '',
      instructionsRefreshing: '',
      iconRefreshing: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 38 38" stroke="#fff">
    <g fill="none" fill-rule="evenodd">
        <g transform="translate(1 1)" stroke-width="2">
            <circle stroke-opacity=".5" cx="18" cy="18" r="18"/>
            <path d="M36 18c0-9.94-8.06-18-18-18">
                <animateTransform attributeName="transform" type="rotate" from="0 18 18" to="360 18 18" dur="1s" repeatCount="indefinite"/>
            </path>
        </g>
    </g>
</svg>`,
    });

    return () => {
      ptr.destroy(); // Clean up on component unmount
    };
  }, []);

  return (
    <ChartbeatSectionProvider>
      <ThemeProvider>
        <ThemedHtml>
          <head>
            {isRaw ? (
              <link rel='manifest' href='/manifest.json' />
            ) : (
              <link rel='manifest' href='/manifest-popnuggets.json' />
            )}
            <Meta />
            <Links />
            {isRaw ? <HeadLinksRaw /> : <HeadLinks />}
            <meta
              name='viewport'
              content='width=device-width, initial-scale=1'
            />
            <link rel='preconnect' href='https://fonts.googleapis.com' />
            <link rel='preconnect' href='https://fonts.gstatic.com' />
            <link
              href='https://fonts.googleapis.com/css2?family=Urbanist:ital,wght@0,100..900;1,100..900&display=swap'
              rel='stylesheet'
            />
            <meta name='apple-mobile-web-app-capable' content='yes' />
            <meta
              name='apple-mobile-web-app-status-bar-style'
              content='default'
            />
            {/* Google tag (gtag.js) */}
            <script
              async
              src={`https://www.googletagmanager.com/gtag/js?id=${googleTagToken}`}
            />
            {isRaw ? (
              <script
                dangerouslySetInnerHTML={{
                  __html: `(function(h,o,t,j,a,r){
              h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
              h._hjSettings={hjid:5299147,hjsv:6};
              a=o.getElementsByTagName('head')[0];
              r=o.createElement('script');r.async=1;
              r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
              a.appendChild(r);
          })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`,
                }}
              />
            ) : (
              <script
                dangerouslySetInnerHTML={{
                  __html: `(function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:5301841,hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`,
                }}
              />
            )}

            <script
              dangerouslySetInnerHTML={{
                __html: `window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', '${googleTagToken}');`,
              }}
            />
          </head>
          <body>
            <RawModeProvider initialMode={isRaw}>
              <UserProvider initialUser={user}>
                <ViewModeProvider>
                  <ToastProvider>
                    <Header
                      setIsMenuOpened={setIsMenuOpened}
                      isMenuOpened={isMenuOpened}
                      topTags={topTags}
                    />
                    <main className={layoutSyles.container}>
                      <div className={layoutSyles.mainHolder}>
                        <Sidebar
                          isTextPage={checkIfTextPage()}
                          isMenuOpened={isMenuOpened}
                          mainTags={tags}
                        />
                        <Outlet />
                        <AnimatePresence>
                          {loading && <Loader />}
                          {showSubscribeModal && (
                            <NewsletterModal
                              onClose={() => setShowSubscribeModal(false)}
                            />
                          )}
                          {showTopicsModal && modalTags && (
                            <TopicsModal
                              hotTags={modalTags.hot_tags}
                              mainTags={modalTags.main_tags}
                              onClose={() => setShowTopicsModal(false)}
                            />
                          )}
                          {showPwaModal && (
                            <DownloadPwaModal
                              onClose={() => setShowPwaModal(false)}
                              onShowInstructions={() =>
                                setShowPwaInstructionsModal(true)
                              }
                            />
                          )}
                          {showPwaInstructionsModal && (
                            <PwaInstructionsModal
                              onClose={() => setShowPwaInstructionsModal(false)}
                            />
                          )}
                          {showNotifsModal && (
                            <PwaNotifsModal
                              onClose={() => setShowNotifsModal(false)}
                            />
                          )}
                        </AnimatePresence>
                      </div>
                    </main>
                    <ToastList />
                    <ScrollRestoration />
                    <Scripts />
                  </ToastProvider>
                </ViewModeProvider>
              </UserProvider>
            </RawModeProvider>
          </body>
        </ThemedHtml>
      </ThemeProvider>
    </ChartbeatSectionProvider>
  );
}

export const ErrorBoundary = () => {
  const error = useRouteError();

  console.error(error);

  if (process.env.NODE_ENV === 'production') {
    captureRemixErrorBoundaryError(error);
  }

  return <div>Something went wrong</div>;
};

const ThemedHtml: FC<PropsWithChildren> = ({ children }) => {
  const [theme] = useTheme();
  return (
    <html lang='en' className={theme ?? 'dark'}>
      {children}
    </html>
  );
};
