import layoutSyles from 'app/scss/layout/layout.module.scss';
import { AnimatePresence } from 'framer-motion';
import { FC, useEffect, useState } from 'react';
import { useRawMode } from '~/context/RawModeContext';
import { useUser } from '~/context/UserContext';
import Burger from '~/icons/Burger';
import Close from '~/icons/Close';
import LogoDefault from '~/icons/LogoDefault';
import LogoRaw from '~/icons/LogoRaw';
import NotificationsIcon from '~/icons/NotificationsIcon';
import { MinimalTagResource } from '~/types';
import { checkSubscriptionStatus } from '~/utils/checkSubscribtionStatus';
import { isPWAInstalled } from '~/utils/helpers';

import { Link } from '@remix-run/react';

import LogInModal from '../LogInModal/LogInModal';
import NotificationsCenterModal from '../NotificationsCenter/NotificationsCenterModal';
import Overlay from '../Overlay/Overlay';
import SearchBar from '../SearchBar/SearchBar';
import ThemeSwitch from '../ThemeSwitch/ThemeSwitch';
import styles from './Header.module.scss';
import HeaderUser from './HeaderUser';

interface Props {
  setIsMenuOpened: React.Dispatch<React.SetStateAction<boolean>>;
  isMenuOpened: boolean;
  topTags?: MinimalTagResource[];
}

const Header: FC<Props> = ({ setIsMenuOpened, isMenuOpened, topTags }) => {
  const { isRaw } = useRawMode();
  const { user } = useUser();

  const [isSubscribed, setIsSubscribed] = useState(false);

  const [showNotifsIndicator, setShowNotifsIndicator] = useState(false);

  const [showNotifsCenter, setShowNotifsCenter] = useState(false);

  const isPwa = isPWAInstalled();
  const [showLoginModal, setShowLoginModal] = useState(false);

  useEffect(() => {
    async function fetchSubscriptionStatus() {
      const status = await checkSubscriptionStatus();
      setIsSubscribed(status);
      if (!isPwa || !status) {
        setShowNotifsIndicator(true);
      }
    }

    fetchSubscriptionStatus();
  }, [isPwa]);

  useEffect(() => {
    const onWindowResize = () => {
      if (window.innerWidth > 1247) {
        setIsMenuOpened(false);
      }
    };

    onWindowResize();

    window.addEventListener('resize', onWindowResize);

    return () => window.removeEventListener('resize', onWindowResize);
  });

  return (
    <header className={styles.header}>
      <div className={layoutSyles.container}>
        <div className={styles.headerInner}>
          <div className={styles.headerLeft}>
            <button
              className={styles.menuButton}
              onClick={() => {
                setIsMenuOpened((prev) => !prev);
                setShowNotifsCenter(false);
              }}
              aria-label='Toggle navigation'
            >
              {isMenuOpened ? <Close /> : <Burger />}
            </button>
            {isRaw ? (
              <Link
                to={'/'}
                className={styles.logoRaw}
                aria-label='Go to Homepage'
              >
                <LogoRaw />
              </Link>
            ) : (
              <Link
                to={'/'}
                className={styles.logo}
                aria-label='Go to Homepage'
              >
                <LogoDefault />
              </Link>
            )}
          </div>
          <div className={styles.headerCenter}>
            <SearchBar
              topTags={topTags}
              closeMenu={() => {
                setIsMenuOpened(false);
                setShowNotifsCenter(false);
              }}
            />
            <button
              className={styles.notificationsButton}
              onClick={() => {
                setShowNotifsCenter((prev) => !prev);
                setIsMenuOpened(false);
              }}
            >
              <NotificationsIcon />
              {showNotifsIndicator && (
                <div className={styles.notifsIndicator} />
              )}
            </button>
          </div>
          <div className={styles.buttons}>
            {user?.is_admin && <ThemeSwitch />}
            {!user ? (
              <button
                className={styles.logIn}
                onClick={() => {
                  setShowNotifsCenter(false);
                  setIsMenuOpened(false);
                  setShowLoginModal(true);
                }}
              >
                Log in
              </button>
            ) : (
              <HeaderUser
                closeMenu={() => {
                  setShowNotifsCenter(false);
                  setIsMenuOpened(false);
                }}
                user={user}
              />
            )}
          </div>
        </div>
      </div>
      <AnimatePresence>
        {isMenuOpened && <Overlay />}
        {showLoginModal && (
          <LogInModal onClose={() => setShowLoginModal(false)} />
        )}
        {showNotifsCenter && (
          <NotificationsCenterModal
            isSubscribed={isSubscribed}
            isPwa={isPwa}
            onClose={() => setShowNotifsCenter(false)}
          />
        )}
      </AnimatePresence>
    </header>
  );
};

export default Header;
