import classNames from 'classnames';
import { useTheme } from '~/context/ThemeContext';
import MoonIcon from '~/icons/MoonIcon';
import SunIcon from '~/icons/SunIcon';

import styles from './ThemeSwitch.module.scss';

enum Theme {
  DARK = 'dark',
  LIGHT = 'light',
}

const ThemeSwitch = () => {
  const [theme, setTheme] = useTheme();

  return (
    <button
      onClick={() => setTheme(theme === Theme.DARK ? Theme.LIGHT : Theme.DARK)}
      className={styles.button}
    >
      <div
        className={classNames(styles.icon, {
          [styles.iconActive]: theme === Theme.LIGHT,
        })}
      >
        <SunIcon />
      </div>
      <div
        className={classNames(styles.icon, {
          [styles.iconActive]: theme === Theme.DARK,
        })}
      >
        <MoonIcon />
      </div>

      <span
        className={classNames(styles.slider, {
          [styles.dark]: theme === Theme.DARK,
          [styles.light]: theme === Theme.LIGHT,
        })}
      />
    </button>
  );
};

export default ThemeSwitch;
