const LogoDefault = () => {
  return (
    <svg
      width='137'
      height='44'
      viewBox='0 0 137 44'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M130.809 28.7697C127.533 28.7697 125.324 26.9497 125.179 24.159H128.285C128.382 25.4209 129.353 26.1731 130.809 26.1731C132.071 26.1731 132.871 25.5665 132.871 24.62C132.871 22.0234 125.543 23.7464 125.543 18.7959C125.543 16.6118 127.411 14.9374 130.323 14.9374C133.502 14.9374 135.541 16.539 135.759 19.1356H132.653C132.507 18.0436 131.658 17.3884 130.348 17.3884C129.304 17.3884 128.576 17.8737 128.576 18.6503C128.576 21.1013 135.978 19.1599 135.978 24.5472C135.978 27.0953 133.866 28.7697 130.809 28.7697Z'
        fill='#E6F3F3'
      />
      <path
        d='M122.439 28.7454C119.891 28.7454 117.949 27.1923 117.949 24.3287V17.7523H116.008V15.1314H117.949V12.3407H121.177V15.1314H124.065V17.7523H121.177V24.0375C121.177 25.2994 121.856 25.9789 122.876 25.9789C123.288 25.9789 123.822 25.8576 124.235 25.712V28.4299C123.677 28.6241 123.118 28.7454 122.439 28.7454Z'
        fill='#E6F3F3'
      />
      <path
        d='M109.459 28.7697C105.285 28.7697 102.64 25.9062 102.64 21.8536C102.64 17.8009 105.479 14.9374 109.362 14.9374C113.705 14.9374 116.375 18.262 115.792 22.5088H105.819C106.061 24.8142 107.493 26.0761 109.459 26.0761C111.085 26.0761 112.152 25.3481 112.444 24.1832H115.744C115.234 26.7798 112.953 28.7697 109.459 28.7697ZM105.964 20.1548H112.444C112.395 18.5047 111.182 17.4369 109.362 17.4369C107.59 17.4369 106.401 18.3591 105.964 20.1548Z'
        fill='#E6F3F3'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M74.12 35.2581C76.564 40.4028 81.8079 43.9597 87.8825 43.9597C93.9571 43.9597 99.2009 40.4028 101.645 35.2581H97.5855C95.4852 38.3745 91.9232 40.4242 87.8825 40.4242C83.8417 40.4242 80.2797 38.3745 78.1795 35.2581H74.12Z'
        fill='#4E80EE'
      />
      <path
        d='M94.7961 33.1865C91.4229 33.1865 88.8623 31.1528 88.4741 28.629H91.7016C92.0414 29.6725 93.1945 30.6869 94.8689 30.6869C97.0044 30.6869 98.2663 29.4736 98.2663 27.5322V26.2945C97.4412 27.4108 95.9124 28.0418 94.2379 28.0418C90.5736 28.0418 88.0255 25.4209 88.0255 21.5624C88.0255 17.534 90.5736 14.9374 94.2137 14.9374C95.9609 14.9374 97.417 15.5927 98.2663 16.6119V15.1316H101.494V27.3866C101.494 31.1237 98.6546 33.1865 94.7961 33.1865ZM91.2773 21.4896C91.2773 23.6979 92.7091 25.2996 94.7718 25.2996C96.8346 25.2996 98.2663 23.6979 98.2663 21.4896C98.2663 19.2813 96.8346 17.6796 94.7718 17.6796C92.7091 17.6796 91.2773 19.2813 91.2773 21.4896Z'
        fill='#E6F3F3'
      />
      <path
        d='M80.3527 33.1865C76.9796 33.1865 74.4994 31.1528 74.1111 28.629H77.3386C77.6784 29.6725 78.7511 30.6869 80.4255 30.6869C82.5611 30.6869 83.823 29.4736 83.823 27.5322V26.2945C82.9979 27.4108 81.469 28.0418 79.7946 28.0418C76.1302 28.0418 73.5822 25.4209 73.5822 21.5624C73.5822 17.534 76.1302 14.9374 79.7703 14.9374C81.5176 14.9374 82.9736 15.5927 83.823 16.6119V15.1316H87.0505V27.3866C87.0505 31.1237 84.2112 33.1865 80.3527 33.1865ZM76.834 21.4896C76.834 23.6979 78.2657 25.2996 80.3285 25.2996C82.3912 25.2996 83.823 23.6979 83.823 21.4896C83.823 19.2813 82.3912 17.6796 80.3285 17.6796C78.2657 17.6796 76.834 19.2813 76.834 21.4896Z'
        fill='#E6F3F3'
      />
      <path
        d='M64.7951 28.7698C61.883 28.7698 59.7717 26.8527 59.7717 23.6979V15.1316H63.0235V23.0427C63.0235 24.8142 64.0913 25.9305 65.6687 25.9305C67.8527 25.9305 69.1389 24.0377 69.1389 20.9557V15.1316H72.3665V28.5757H69.1389V26.61C68.4594 27.7749 66.8578 28.7698 64.7951 28.7698Z'
        fill='#E6F3F3'
      />
      <path
        d='M45.8507 28.5756V15.1315H49.0783V16.9516C49.8063 15.8595 51.4807 14.9374 53.3978 14.9374C56.3099 14.9374 58.4454 16.8545 58.4454 19.718V28.5756H55.2179V20.5431C55.2179 18.8929 54.1987 17.7767 52.5728 17.7767C50.51 17.7767 49.0783 19.4026 49.0783 22.533V28.5756H45.8507Z'
        fill='#E6F3F3'
      />
      <path
        d='M23.1196 28.7697C19.0427 28.7697 16.1306 25.9062 16.1306 21.8536C16.1306 17.8009 19.0427 14.9374 23.1196 14.9374C27.1722 14.9374 30.1086 17.8009 30.1086 21.8536C30.1086 25.9062 27.1722 28.7697 23.1196 28.7697ZM19.3824 21.8536C19.3824 24.3288 20.9113 26.0033 23.1196 26.0033C25.3037 26.0033 26.8325 24.3288 26.8325 21.8536C26.8325 19.3783 25.3037 17.6796 23.1196 17.6796C20.9113 17.6796 19.3824 19.3783 19.3824 21.8536Z'
        fill='#4E80EE'
      />
      <path
        d='M1.45569 32.9923V15.1316H4.68324V16.8546C5.50833 15.6897 7.13424 14.9374 8.93002 14.9374C12.74 14.9374 15.3609 17.6311 15.3609 21.8536C15.3609 26.0761 12.74 28.7698 8.88148 28.7698C7.10997 28.7698 5.50833 28.0175 4.68324 26.8527V32.9923H1.45569ZM4.68324 21.8536C4.68324 24.3289 6.21208 26.0033 8.39614 26.0033C10.5802 26.0033 12.109 24.3289 12.109 21.8536C12.109 19.3784 10.5802 17.6796 8.39614 17.6796C6.21208 17.6796 4.68324 19.3784 4.68324 21.8536Z'
        fill='#4E80EE'
      />
      <path
        d='M30.9354 32.9923V15.1316H34.163V16.8546C34.9881 15.6897 36.614 14.9374 38.4098 14.9374C42.2197 14.9374 44.8406 17.6311 44.8406 21.8536C44.8406 26.0761 42.2197 28.7698 38.3612 28.7698C36.5897 28.7698 34.9881 28.0175 34.163 26.8527V32.9923H30.9354ZM34.163 21.8536C34.163 24.3289 35.6918 26.0033 37.8759 26.0033C40.0599 26.0033 41.5888 24.3289 41.5888 21.8536C41.5888 19.3784 40.0599 17.6796 37.8759 17.6796C35.6918 17.6796 34.163 19.3784 34.163 21.8536Z'
        fill='#4E80EE'
      />
    </svg>
  );
};

export default LogoDefault;
