import { FC } from 'react';
import { useRawMode } from '~/context/RawModeContext';

import styles from './NotificationsCenter.module.scss';

interface Props {
  onInstall: () => void;
}

const DownloadPwa: FC<Props> = ({ onInstall }) => {
  const { isRaw } = useRawMode();

  return (
    <div className={styles.pwaBox}>
      <div className={styles.topRow}>
        <div className={styles.icon}>
          {isRaw ? (
            <img src='/favicon/raw/android-chrome-192x192.png' alt='app icon' />
          ) : (
            <img
              src='/favicon/default/android-chrome-192x192.png'
              alt='app icon'
            />
          )}
        </div>
        <div>
          <div className={styles.pwaTitle}>
            {isRaw ? '8legs' : 'Popnuggets'}
          </div>
          <div className={styles.pwaDescription}>
            Easiest Access to Your Daily News
          </div>
        </div>
      </div>
      <button onClick={onInstall} className={styles.downloadButton}>
        Add
      </button>
    </div>
  );
};

export default DownloadPwa;
