import { motion } from 'framer-motion';
import { FC } from 'react';
import { useRawMode } from '~/context/RawModeContext';
import { useInstallPrompt } from '~/hooks/useInstallPrompt';
import Close from '~/icons/Close';
import { handleGAPWAEvent, setCookie } from '~/utils/helpers';

import styles from './DownloadPwaModal.module.scss';

interface Props {
  onShowInstructions: () => void;
  onClose: () => void;
}

const DownloadPwa: FC<Props> = ({ onShowInstructions, onClose }) => {
  const { isRaw } = useRawMode();

  const { handleInstallClick } = useInstallPrompt(onShowInstructions, onClose);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.1 }}
      className={styles.box}
    >
      <button
        className={styles.closeButton}
        onClick={() => {
          setCookie('pwa_modal', 'seen', 7);
          handleGAPWAEvent('promo-close');
          onClose();
        }}
      >
        <Close />
      </button>
      <div className={styles.message}>
        <h3 className={styles.title}>{isRaw ? '8legs' : 'Popnuggets'}</h3>
        <div className={styles.description}>
          <p>Homescreen App</p>
        </div>
      </div>
      <button
        className={styles.addButton}
        onClick={() => {
          handleInstallClick();
          handleGAPWAEvent('promo-click');
          setCookie('pwa_modal', 'seen', 7);
        }}
      >
        Add
      </button>
    </motion.div>
  );
};

export default DownloadPwa;
